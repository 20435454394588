<template>
  <el-dialog :title="$t('Share your photo')" width="700px" :close-on-click-modal="false" :visible.sync="visible">
    <upload-img-item v-for="(item,index) of resources"
                     :index="index"
                     :item="item"
                     :len="resources.length"
                     @delete="deleteItem"
                     :key="item.resourceId"/>
    <div class="txt-c" v-if="resources.length<9">
      <a class="btn-add" @click="addItem">
        <img class="vam" src="../../../assets/images/icon/plus-circle-red.png">
        <span class="vam"> {{$t('AddPicture')}}</span>
      </a>
    </div>
    <div class="actions">
      <!--<button class="button m-r">{{$t('Cancel')}}</button>-->
      <button class="button button-primary" v-on:click="hide()">{{$t('OK')}}</button>
    </div>
  </el-dialog>
</template>
<script>
  import UploadImgItem from './UploadImgItem';

  export default {
    name: 'UploadImgs',
    components: {UploadImgItem},
    props: {
      resources: Array
    },
    data() {
      return {
        visible: false
      };
    },
    methods: {
      show() {
        this.visible = true;
      },
      hide() {
        this.visible = false;
      },
      addItem() {
        this.$emit('add');
      },
      deleteItem(index) {
        this.resources.splice(index, 1);
      }
    }
  };
</script>
<style scoped lang="less">
  .actions{margin-top:30px;text-align:center;}
</style>
