<template>
  <div class="main" :class="{disabled:$route.query.type==='preview'}">
    <div class="goods-info">
      <div class="cover">
        <img class="img" :src="product.photoUrl" alt=""/>
      </div>
      <div class="infos">
        <div class="title">{{product.title}}</div>
        <div class="introduction">{{product.introduction}}</div>
      </div>
    </div>
    <div class="rate-wrap cl">
      <!--你的评分-->
      <div class="fl">{{$t('Your rating')}}：</div>
      <el-rate class="fl" void-color="#ff6F61" :colors="['#ff6f61','#ff6f61','#ff6f61']" v-model="rate"/>
    </div>
    <div class="ipt-wrap">
      <div class="item">
        <input type="text" autocomplete="off" :placeholder="$t('PleaseEnterATitle')" v-model.trim="title"/>
      </div>
      <div class="item">
        <textarea rows="10" maxlength="500" :placeholder="$t('PleaseEnterTheContent')" v-model.trim="content">
        </textarea>
      </div>
    </div>
    <div class="upload-wrap">
      <!--分享你的相片（选填）：接受JPEG/GIF/PNG（少于2MB）/图片格式。-->
      <p class="ps">{{$t('ShareYourPhotos')}}</p>
      <div class="upload-list cl" @click="()=>$refs.uploadImgs.show()">
        <template v-for="item of resources">
          <div class="item" :key="item.resourceId" v-if="item.resourceUrl">
            <img class="img" :src="item.resourceUrl"/>
          </div>
        </template>
        <div class="item btn-upload">
          <img src="../../assets/images/icon/plus.png"/>
          <!--上传照片-->
          <span>{{$t('UploadPhotos')}}</span>
        </div>
      </div>
    </div>
    <div class="actions cl">
      <!--取消-->
      <a class="btn-gray" @click="$router.go(-1)">{{$t('Cancel')}}</a>
      <!--提交-->
      <a class="btn-primary" :disabled="disableSubmit" @click="onSubmit">{{$t('Submit')}}</a>
    </div>
    <upload-imgs :resources="resources" @add="addResourceItem" ref="uploadImgs"/>
  </div>
</template>
<script>
  import UploadImgs from './components/UploadImgs';
  import {getCommentDetail, saveComment} from '../../api/comment';
  import {getProductDetail} from '../../api/product';

  export default {
    name: 'OrderCommentDetail',
    data() {
      return {
        orderId: '',
        title: '',
        content: '',
        rate: 5,
        resources: [],
        product: {}
      };
    },
    computed: {
      disableSubmit() {
        return this.resources.length !== this.resources.filter(item => item.resourceUrl).length;
      }
    },
    components: {UploadImgs},
    created() {
      this.orderId = this.$route.params.id;
      this.getDetail();
    },
    methods: {
      // 获取评论详情
      getDetail() {
        if (!this.orderId) {
          return false;
        }
        getCommentDetail(this.orderId).then(res => {
          const value = res.value;
          this.title = value.title || '';
          this.content = value.content;
          this.rate = value.score;
          this.resources = (value.resources || []).map((item, index) => {
            return {
              resourceId: item.resourceId,
              resourceUrl: item.resourceUrl,
              resourceTitle: item.resourceTitle,
              resourceMemo: item.resourceMemo,
              sort: item.sort
            };
          });
          this.getProductDetail(value.productId);
        });
      },
      getProductDetail(productId) {
        if (!productId) {
          return false;
        }
        getProductDetail(productId).then(res => {
          this.product = res.value.product || {};
        });
      },
      addResourceItem() {
        this.resources.push({
          resourceId: new Date().getTime(),
          resourceUrl: '',
          resourceTitle: '',
          resourceMemo: '',
          sort: 0
        });
      },
      // 提交
      onSubmit() {
        const data = {
          title: this.title,
          content: this.content,
          score: this.rate,
          resources: this.resources.filter(item => item.resourceUrl).map((item, index) => {
            item.sort = index + 1;
            return item;
          })
        };

        if (!data.title) {
          this.$message.warning(this.$t('PleaseEnterATitle'));
          return false;
        }
        if (!data.content) {
          this.$message.warning(this.$t('PleaseEnterTheContent'));
          return false;
        }

        const loading = this.$loading({text: 'Loading'});
        saveComment(this.orderId, data).then(res => {
          loading.close();
          this.$message.success(this.$t('SaveSuccessfully'));
          this.$router.go(-1);
        }).catch(() => {
          loading.close();
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .main{
    &.disabled{
      position:relative;
      &:after{position:absolute;top:0;right:0;bottom:0;left:0;z-index:1000;content:'';}
      .actions{display:none;}
    }
  }
  .goods-info{
    padding-bottom:16px;overflow:hidden;border-bottom:1px dashed #ff6f61;
    .cover{float:left;width:210px;height:148px;}
    .infos{margin-left:222px;}
    .title{margin-bottom:12px;}
    .introduction{display:-webkit-box;height:88px;line-height:22px;overflow:hidden;-webkit-line-clamp:4;-webkit-box-orient:vertical;color:#666;}
  }
  .rate-wrap{margin-top:32px;}
  .ipt-wrap{
    margin-top:32px;padding:0 20px;background-color:#f4f4f4;
    .item{
      border-bottom:1px dashed #e0e0e0;
      &:last-child{border-bottom:none;}
    }
    input{width:100%;height:62px;border:none;background-color:transparent;}
    textarea{width:100%;padding:20px 0;border:none;background-color:transparent;}
  }
  .upload-wrap{
    margin-top:32px;
    .ps{color:#999;font-size:14px;}
  }
  .upload-list{
    cursor:pointer;
    > div{position:relative;float:left;margin-top:10px;margin-right:10px;}
    .item{width:140px;height:140px;}
    .btn-upload{
      text-align:center;overflow:hidden;color:#999;font-size:16px;background-color:#f4f4f4;
      img{display:block;margin:40px auto 8px;}
    }
  }
  .actions{
    margin-top:40px;
    a:hover{opacity:0.8;}
  }
  .btn-primary,
  .btn-gray{float:left;margin-right:8px;padding:0 48px;font-size:18px;border-radius:4px;}
  .btn-primary{line-height:44px;color:#fff;background-color:#ff6f61;}
  .btn-gray{line-height:42px;color:#ff6f61;border:1px solid #ff6f61;}
</style>
