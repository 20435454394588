<template>
  <div class="main">
    <h1 class="h1">{{$t('MyComment')}}</h1>
    <div v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.01)">
      <Tabs v-model="activeName" @tab-click="handleTabClick">
        <TabPane :label="$t('All')" name=""></TabPane>
        <TabPane :label="$t('Comment')" name="NOT_COMMENT"></TabPane>
        <TabPane :label="$t('Reviewed')" name="FINISH"></TabPane>
      </Tabs>
      <div class="order-list">
        <div class="order-item" v-for="item of list" :key="item.orderId">
          <div class="hd">
            <div class="fr">
              <star :value="`${item.score*100/5}%`" v-if="item.orderStatus==='FINISH'"/>
              <router-link class="btn-primary"
                           target="_blank"
                           :to="{name:'OrderCommentDetail',params:{id:'add'}}"
                           v-else>{{$t('Immediate evaluation')}}
              </router-link>
            </div>
            <div class="title">
              <!--订单编号-->
              <p>{{$t('Order number')}}：{{item.orderNo}}</p>
              <!--产品标题-->
              <p>{{$t('ProductName')}}：{{item.productTitle}}</p>
            </div>
          </div>
          <template v-if="item.orderStatus==='FINISH'">
            <div class="bd">
              <!--评价标题-->
              <p class="title" v-if="item.contentTitle">{{item.contentTitle}}</p>
              <p class="cont">{{item.content}}</p>
            </div>
            <div class="ft">
              <router-link class="btn-primary"
                           :to="{name:'OrderCommentDetail',params:{id:item.orderId},query:{type:'preview'}}">
                {{$t('SeeDetails')}}
              </router-link>
              <router-link class="btn-gray" :to="{name:'OrderCommentDetail',params:{id:item.orderId}}">
                {{$t('Modify')}}
              </router-link>
              <!--<a class="btn-gray">{{$t('Delete')}}</a>-->
            </div>
          </template>
        </div>
        <empty v-if="isEmpty" :title="$t('NoData')"/>
      </div>
      <el-pagination class="pages"
                     background
                     layout="prev, pager, next"
                     :page-count="totalPages"
                     @current-change="changePage"
                     hide-on-single-page>
      </el-pagination>
    </div>
  </div>
</template>
<script>
  import Star from '../../components/Star';
  import Empty from '../../components/Empty';
  import {scrollToY} from '../../utils';
  import {getCommentList} from '../../api/comment';

  export default {
    name: 'OrderComment',
    data() {
      return {
        activeName: '',
        list: [],
        totalPages: 1,
        isEmpty: false,
        loading: false
      };
    },
    components: {Empty, Star},
    created() {
      this.getList();
    },
    methods: {
      getList(page = 1) {
        const data = {page, perPage: 10, orderStatus: this.activeName};
        this.loading = true;
        getCommentList(data).then(res => {
          const {value} = res;
          this.list = value.list || [];
          this.totalPages = value.totalPages;
          this.isEmpty = data.page === 1 && !this.list.length;
          this.loading = false;
          this.$nextTick(() => {
            scrollToY();
          });
        }).catch(() => {
          this.loading = false;
        });
      },
      changePage(page) {
        this.getList(page);
      },
      handleTabClick(tabName) {
        this.getList();
      }
    }
  };
</script>
<style scoped lang="less">
  .order-list{margin-top:20px;}
  .order-item{
    margin-bottom:8px;padding:20px;background-color:#f4f4f4;border-radius:4px;
    .hd{
      padding-bottom:4px;overflow:hidden;border-bottom:1px dashed #e0e0e0;
      > .fr{margin-top:16px;}
      .title{margin-right:180px;}
    }
    .bd{
      .title{margin-top:4px;}
      .cont{margin-top:4px;color:#555;}
    }
    .ft{margin-top:10px;text-align:right;font-size:0;}
    .btn-primary,
    .btn-gray{
      display:inline-block;margin-left:20px;padding:0 12px;font-size:12px;border-radius:4px;user-select:none;
      &:hover{opacity:0.8;}
    }
    .btn-primary{line-height:20px;color:#fff;background-color:#ff6f61;}
    .btn-gray{line-height:18px;color:#321908;border:1px solid #e6e6e6;background-color:#fff;}
  }
</style>
