import request from '@/utils/request';

// 获取评价列表（分页）
export function getCommentList(data) {
  return request({
    url: '/comment/list',
    method: 'get',
    params: data
  });
}

// 获取评论详情
export function getCommentDetail(orderId) {
  return request({
    url: `/comment/${orderId}`,
    method: 'get'
  });
}

// 保存评论
export function saveComment(orderId, data) {
  return request({
    url: `/comment/save/${orderId}`,
    method: 'post',
    data
  });
}
